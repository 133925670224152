<template lang="pug">
.create-event
  v-container
    v-row
      v-col
        h1 {{$t('users.create')}}
    v-row
      v-col
        v-card
          v-card-text
            v-row
              v-col
                v-text-field(v-model="newUser.username" :label="$t('users.username')" :hint="$t('users.usernameHint')"  type="text" :rules="[() => !!newUser.username || $t('users.usernameRequired')]")
            v-row
              v-col
                v-text-field(v-model="newUser.fullname" :label="$t('users.fullname')" :hint="$t('users.fullnameHint')"  type="text")
            v-row
              v-col
                v-text-field(v-model="newUser.email" :label="$t('users.email')" :hint="$t('users.emailHint')"  type="email" :rules="[() => !!newUser.email || $t('users.emailRequired')]")
            v-row
              v-col
                v-text-field(v-model="newUser.password" :label="$t('users.password')" :hint="$t('users.passwordHint')"  type="text" :rules="[() => !!newUser.password || $t('users.passwordRequired')]")
            v-row
              v-col
                v-btn(block color="#8a2387" :disabled="!formValid" @click="submit") {{$t('users.create')}}

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { clone } from 'lodash'
export default {
  name: 'UsersCreate',
  metaInfo () { return { title: this.$t('users.create') } },
  data () {
    return {
      newUser: {
        username: '',
        fullname: '',
        email: '',
        password: ''
      }
    }
  },
  computed: {
    ...mapGetters({ users: 'users/asArray' }),
    formValid () {
      if (!this.newUser.username) return false
      if (!this.newUser.email) return false
      if (!this.newUser.password) return false
      return true
    },
    preview () {
      // console.log(this.newUser.description)
      return this.marked(this.newUser.description)
    }
  },
  methods: {
    ...mapActions({ createUser: 'users/postEntry' }),
    submit () {
      const newObject = clone(this.newUser)
      newObject.plainPassword = newObject.password
      this.createUser(newObject).then((newUser) => {
        this.$awn.success(this.$t('users.saved'))
        this.$router.push({ name: 'UsersList' })
      }).catch(e => {
        console.error({ e })
        if (e.response.data.detail) {
          this.$awn.alert(this.$t('users.savedError') + e.response.data.detail)
        } else {
          this.$awn.alert(this.$t('users.savedError') + e)
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
