<template lang="pug">
.create-user
  v-container
    v-row
      v-col
        h1 {{$t('users.update')}}
    v-row
      v-col
        v-card(:loading="isLoading")
          v-card-text
            v-row
              v-col(:md="4")
                v-text-field(v-model="localUser.username" :label="$t('users.name')" :hint="$t('users.nameHint')"  type="text" :rules="[() => !!localUser.username || $t('users.usernameRequired')]")
              v-col(:md="4")
                v-text-field(v-model="localUser.fullname" :label="$t('users.fullname')" :hint="$t('users.fullnameHint')"  type="text")
              v-col(:md="4")
                v-text-field(v-model="localUser.email" :label="$t('users.email')" :hint="$t('users.emailHint')"  type="email" :rules="[() => !!localUser.email || $t('users.emailRequired')]")
            v-row
              v-col(:md="4")
                v-text-field(v-model="localUser.plainPassword" :label="$t('users.passwordEdit')" :hint="$t('users.passwordHint')"  type="text")
              v-col(:md="8")
                h3 Rechte/Gruppen:
                v-btn(:color="isAdmin?'green':'lightgrey'" @click="toggleRole('ROLE_SUPER_ADMIN')").text-none.mr-2 Administrator
                v-btn(:color="isPerformer?'green':'lightgrey'" @click="toggleRole('ROLE_PERFORMER')").text-none.mr-2 Musiker
                v-btn(:color="isTechnician?'green':'lightgrey'" @click="toggleRole('ROLE_TECHNICIAN')").text-none.mr-2 Produktion
                v-btn(:color="isTechnicianLead?'green':'lightgrey'" @click="toggleRole('ROLE_TECHNICIAN_LEAD')").text-none.mr-2 Produktionsleiter
                v-btn(:color="isOrgaTeam?'green':'lightgrey'" @click="toggleRole('ROLE_ORGA_TEAM')").text-none.mr-2 Orga Team
            v-row
              v-col
                v-btn(block color="#8a2387" :disabled="!formValid" @click="submit") {{$t('users.save')}}
            //v-row
            //  v-col
            //    pre {{localUser}}

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { clone, cloneDeep } from 'lodash'
import { isAdmin, isPerformer, isTechnician, userHasRole, isTechnicianLead, isOrgaTeam } from '@/modules/Authentication/helper/Checks'
export default {
  name: 'UsersEdit',
  props: ['userId'],
  metaInfo () { return { title: this.$t('users.update') } },

  data () {
    return {
      localUser: {}
    }
  },
  mounted () {
    this.updateLocalUser()
  },
  watch: {
    user (val) {
      this.updateLocalUser()
    }
  },
  computed: {
    ...mapGetters({
      getUser: 'users/byId',
      isLoading: 'users/isLoading'
    }),
    isAdmin () {
      return isAdmin(this.localUser)
    },
    isPerformer () {
      return isPerformer(this.localUser)
    },
    isTechnician () {
      return isTechnician(this.localUser)
    },
    isTechnicianLead () {
      return isTechnicianLead(this.localUser)
    },
    isOrgaTeam () {
      return isOrgaTeam(this.localUser)
    },
    formValid () {
      if (!this.localUser.username) return false
      if (!this.localUser.email) return false
      return true
    },
    user () {
      const user = this.getUser(this.userId)
      if (!user) this.loadUsers()
      return user
    }
  },
  methods: {
    ...mapActions({
      loadUsers: 'users/getEntries',
      updateUser: 'users/putEntry'
    }),
    toggleRole (role) {
      if (userHasRole(this.localUser, role)) {
        this.localUser.roles.splice(this.localUser?.roles?.indexOf(role), 1)
      } else {
        this.localUser.roles.push(role)
      }
    },
    updateLocalUser () {
      this.$set(this, 'localUser', cloneDeep(this.user))
    },
    submit () {
      const newObject = clone(this.localUser)
      if (!newObject.plainPassword || newObject.plainPassword.trim() === '') {
        delete newObject.plainPassword
      }
      this.updateUser(newObject).then(() => {
        this.$awn.success(this.$t('users.saved'))
        this.$router.push({ name: 'UsersList' })
      }).catch(e => {
        console.error({ e })
        if (e.response.data.detail) {
          this.$awn.alert(this.$t('users.savedError') + e.response.data.detail)
        } else {
          this.$awn.alert(this.$t('users.savedError') + e)
        }
      })
    }
  }

}
</script>

<style scoped>

</style>
