<template lang="pug">
.events-list
  v-container
    v-row
      v-col
        h1 {{$t('users.heading')}}
          .float-end(v-if="hasRole('ROLE_USER_CREATE')")
            v-btn(color="#8a2387" :to="{name:'UsersCreate'}").text-none
              v-icon.mr-3 fa-plus
              |  {{$t('users.create')}}
    v-row
      v-col
        v-data-table(:headers="tableHeaders" :loading="isLoading" :no-data-text="$t('noUsers')" :items="localUsers" )
          template(v-slot:item.actions="{item}")
            v-btn.text-none.mr-2(:to="{name:'UsersEdit', params:{userId:item.id}}")  {{$t('users.edit')}}
            v-btn.text-none(@click="startImpersonating(item)")  {{$t('users.impersonate')}}
          template(v-slot:item.isActive="{item}")
            v-switch.text-none(v-if="item.id!==profile.id" v-model="item.isActive" :loading="isLoading" @click="updateActive(item)")
          template(v-slot:item.fullname="{item}") {{item.fullname}}
            v-chip(v-if="isAdmin(item)" small color="green" ).mx-2 Admin
            v-chip(v-if="isTechnicianLead(item)" small color="blue" ).mx-2 Produktionsleitung
            v-chip(v-if="isTechnician(item)" small color="blue" ).mx-2 Techniker/in
            v-chip(v-if="isPerformer(item)" small color="rgb(138, 35, 135)" text-color="white" ).mx-2 Künstler/in
            v-chip(v-if="isOrgaTeam(item)" small color="yellow" text-color="black" ).mx-2 Orga Team

</template>

<script>
import AppEventsCalendar from '@/modules/Events/components/app-events-calender'
import { mapActions, mapGetters } from 'vuex'
import { cloneDeep } from 'lodash'
import {
  hasRole,
  isAdmin,
  isTechnician,
  isPerformer,
  isTechnicianLead,
  isOrgaTeam
} from '@/modules/Authentication/helper/Checks'
export default {
  name: 'UsersList',
  components: { AppEventsCalendar },
  metaInfo () { return { title: this.$t('users.heading') } },

  data () {
    return {
    }
  },
  watch: {
  },
  methods: {
    isOrgaTeam,
    hasRole,
    isAdmin,
    isTechnician,
    isPerformer,
    isTechnicianLead,
    ...mapActions({
      loadEvents: 'users/getEntries',
      putUser: 'users/putEntry',
      loadProfile: 'users/loadProfile',
      setImpersonatedUser: 'authentication/startImpersonate'
    }),
    startImpersonating (user) {
      this.setImpersonatedUser(user)
    },

    updateActive (user) {
      this.putUser(user)
    }
  },
  computed: {
    tableHeaders () {
      const heading = []
      if (hasRole('ROLE_ADMIN')) {
        heading.push({
          text: this.$t('users.email'),
          align: 'start',
          sortable: true,
          value: 'email'
        })
      }
      heading.push({
        text: this.$t('users.fullname'),
        align: 'start',
        sortable: true,
        value: 'fullname'
      }, {
        text: this.$t('users.username'),
        align: 'start',
        sortable: true,
        value: 'username'
      })
      if (hasRole('ROLE_ADMIN')) {
        heading.push({
          text: this.$t('users.active'),
          align: 'start',
          sortable: false,
          value: 'isActive'
        })
      }
      heading.push({
        text: this.$t('users.assignments'),
        align: 'start',
        sortable: false,
        value: 'assignments.length'
      }
      )
      if (hasRole('ROLE_ADMIN')) {
        heading.push({
          text: this.$t('users.actions'),
          align: 'start',
          sortable: false,
          value: 'actions'
        })
      }

      return heading
    },

    ...mapGetters({
      isLoading: 'users/isLoading',
      users: 'users/asListAll',
      profile: 'users/profile'
    }),
    localUsers () { return cloneDeep(this.users) }
  },
  mounted () {
    this.loadEvents()
    this.loadProfile()
  }
}
</script>

<style scoped>

</style>
